@import './color';
@import './typography';

body {
  background-color: #FFF;
  font-family: $rubik !important;
  line-height: 19px;
  padding: 0 !important;
}

.seccion {
  padding-top: 75px !important;
  padding-bottom: 75px !important;

  @media (max-width: 768px) {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

}

.close { font-size: 1.75rem !important; cursor: pointer;}

.circle {
  padding: 12px;
  border-radius: 50%;
  border: 1px solid $primary;
  background-color: $primaryLight;
}

.hero {
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.home {
    z-index: -1;
  }

  &.vende {
    background-image: url('../../images/hero_vende.png');
    z-index: -1;

    @media (max-width: 768px) {
      height: 45vh;
    }
  }

  h1 {
      color: $white;
      text-align: center;
      max-width: 27%;
      font-size: 2.6rem !important;
      
      @media (max-width: 1700px) {
        max-width: 40%;
        font-size: 2.6rem !important;
    }

      @media (max-width: 1024px) {
          max-width: 70%;
      }

      @media (max-width: 768px) {
          max-width: 80%;
          font-size: 1.9rem !important;
          padding: 0 5rem;
          margin-bottom: 3rem !important;
        }
        
      @media (max-width: 610px) {
          max-width: 90%;
          padding: 0 2rem;
          
      }  

      @media (max-width: 489px) {
        max-width: 100%;
        font-size: 1.6rem !important;
        padding: 0 1rem;
    }
  }

  &-highlighted-container {

    @media (max-width: 992px) {
      max-width: 95%;
    }

    @media (min-width: 1200px) {
      max-width: 68rem !important;
    }
    
  }

  &-highlighted {
      background-color: $white;
      padding: 30px 20px;
      border-radius: 6px;
      box-shadow: 0px 0px 20px $boxShadow;
      margin-top: -40px;


      @media (max-width: 768px) {
        margin-top: -70px;
        border-radius: 12px;
        padding: 50px 15px;
      }

      p {
          color: $primary !important;
          padding: 0 12px;
          font-weight: 500;

          @media (max-width: 992px) {
            font-size: 0.8rem !important;

          }

          @media (max-width: 768px) {
            font-size: 1.1rem !important;
            padding: 0 5rem;
          }
          
          @media (max-width: 531px) {
            font-size: 0.9rem !important;
            padding: 0 4rem;
          }

          @media (max-width: 420px) {
            font-size: 0.9rem !important;
            padding: 0 1rem;
          }
      }

      span.divider {
          flex-basis: 1px;
          flex-grow: 0;
          flex-shrink: 0;
          height: 52px;
          background-color: $primary;
          margin: 0 10px;

          @media (max-width: 768px) {
            width: 20%;
            height: 2px;
            margin: 20px 0;
          }

          @media (max-width: 425px) {
            width: 30%;
            height: 2px;
            margin: 20px 0;
            
          }
      }
  }
}

.feature-content {
  margin-top: -50px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.feature-item {
  border-radius: 6px;
  background-color: $backgroundGray;
  padding: 42px 15px;
  text-align: center;

  img { 
    width: 34px; 
    height: 34px; 
  }

  &__car-img {
    width: 43px !important;
    height: 38px !important;
  }

  @media (max-width: 992px) {
    h4 {
      font-size: 1rem !important;
    }
  }

  @media (max-width: 768px) {
    min-height: auto;
    padding: 30px 10px 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80% !important;

    img { width: 30px; height: 30px; }

    h4 {
      font-size: 1rem !important;
    }

    p {
      font-size: 0.8rem !important;
    }
  }
}

.feature-item-hero2 {
  height: 100%;
  width: 95%;
  padding: 30px 30px;
  
  @media (max-width: 768px) {
    min-height: 152px;
    width: 100% !important;
  }
  h4 {
    font-size: 1.5rem !important;
  }
  @media (max-width: 1400px) {
    width: 97%;
    padding: 30px 20px;
    h4 {
      font-size: 1.25rem !important;
    };
    p {
      font-size: 0.9rem !important;
    }
  }

  &__recambistas {

    @media (min-width: 768px) {
      padding:0 1rem;
    }

    @media (min-width: 1198px) {
      padding:0 2rem;
    }
  }

  &__precio {
      padding: 0 1.5rem;
    }
  
    img {
      height: 40px;
      width: 40px;
      margin-bottom: 0.2rem;
      @media (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
    }
  
}

.feature-item-feature {
  width: 100%;
  max-width: 420px;
  min-height: 256px;

  @media (max-width: 768px) {
    max-width: inherit;
    min-height: 120px;
    height: 100%;
  }

}


.content-info {
  div {
    margin: 20px 0;
  }

  h1 { 
    margin-bottom: 25px !important; 
    text-align: center !important; 
    text-transform: uppercase; 
  }
  h4 { 
    margin-top: 30px !important;
    margin-bottom: 15px !important; 
    text-transform: uppercase; 
  }
  h5 { 
    margin-top: 30px !important;
    margin-bottom: 15px !important; 
    font-weight: 700; 
  }
  p { 
    text-align: justify !important; 
    margin-bottom: 10px !important;
  }

  ul, li {
    list-style: square !important;
  }

  ul {
    margin: 10px 0;

    li { 
      text-align: justify; 
      margin: 10px 0;
    }
  }
}

.gutter-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.gutter-20 > [class^='col-'],
.gutter-20 > [class*=' col-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.gutter-vertical-20 > [class^='col-'],
.gutter-vertical-20 > [class*=' col-'] {
  padding-top: 10px;
  padding-bottom: 10px;
}


@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading {
  -webkit-animation: rotating 3s linear infinite;
  -moz-animation: rotating 3s linear infinite;
  -ms-animation: rotating 3s linear infinite;
  -o-animation: rotating 3s linear infinite;
  animation: rotating 3s linear infinite;
}