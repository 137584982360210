@import '../../../../styles/base/color';

.home {
    @media (max-width: 768px) {
        h2 {
            font-size: 2rem !important;
            line-height: 2.4rem !important;
        }
    }

    .seccion-hero2 {
        padding-top: 3rem !important;
        @media (max-width: 768px) {
           padding: 0.8rem 0rem !important;
        }

        .feature-content > div > div {
            padding: 0px 5px;

            @media (max-width: 992px) {
                padding: 0.4rem 0.4rem;
             }

             @media (max-width: 768px) {
                padding: 0.4rem 0rem;
             }

        }


    }

    .hero2 {
        height: 80vh;
        background-image: url('../../../../images/main2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 768px) {
            background-image: none;
            height: auto;
            padding: 0;
        }

        .container {
            padding: 0;
            max-width: none;
        }

        .hero2-text {
            width: 35rem;
            padding-left: 5rem;
            padding-right:calc(var(--bs-gutter-x) * .5);

            h5 {

                @media (max-width: 425px) {
                    font-size: 1rem !important;
                    font-weight: 400;
                }
            }
    
            h2 {
                @media (max-width: 425px) {
                    font-size: 1.4rem !important;
                    line-height: 1.6rem !important;
                    padding-right: 4rem;
                }
            }
    
            p { 
                @media (max-width: 425px) {
                    font-size: 0.9rem !important;
                }
            }

            @media (max-width: 1199px) {
                padding-left: 3rem;
            }

            @media (max-width: 768px) {
                width: 100%;
                max-width: 540px;
                align-self: center;
                padding-left: 0.8rem;
            }
        }

        .hero2-img {
            @media (max-width: 768px) {
                background-image: url('../../../../images/main2_phone.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 240px;
                margin-bottom: 40px;
                width: 100vw;
               
            }
            @media (max-width: 425px){
                height: 208px;
            }
        }
    }

    .es-taller {

        &__container{

            background: $white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            border: 1px solid $primary;
            box-shadow: none;
            padding: 28px 5px;
            
            @media (min-width: 768px) {
                box-shadow: 0px 0px 20px $boxShadow;
                border: none;
                padding: 35px 5px;
                max-width: 40rem;
            }
        
            h4 {
                @media (max-width: 425px) {
                    font-size: 1.2rem !important;
                }
            }
    
            p { max-width: 75%; 
                @media (max-width: 425px) {
                    max-width: 89%;
                    font-size: 0.8rem !important;
                    
                }
            }
    
            button {
                @media (max-width: 425px) {
                    font-weight: 600;
                }
            }
    
            
        
        }
        
        @media (max-width: 425px) {
            padding: 2rem 0rem !important;
        }
        
    }

    .procedimiento {
        background-color: $backgroundGray;

        h2 { 
            font-weight: 700 !important;
        }
        p { color: $fontPrimary !important; }
        h5 {
            margin-top: 1rem !important;
        }

        @media (max-width: 992px) {
            h2 {
                
                font-size: 1.4rem !important;
            }
            h5 {
                font-size: 1.2rem !important;
                margin: 0 !important;
            }
            h4 {
              font-size: 1.2rem !important;
            }
        }

        .procedimiento-steps {
            margin-top: 70px;
            column-gap: 20px;

            @media (max-width: 1024px) {
                column-gap: 45px;
                margin-top: 50px;
            }

            .procedimiento-step {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;   
                
                @media (max-width: 768px) {
                    padding-bottom: 3.3rem;
                }

                h4 {
                    font-size: 1.4rem !important;
                }

                p {
                    font-size: 0.9rem !important;

                    @media (max-width:768px) {
                        padding: 0 4rem;
                    }

                    @media (min-width: 1500px){
                        padding: 0 1rem !important;
                    }

                    @media (min-width: 1800px){
                        padding: 0 2rem !important;
                    }

                    @media (min-width: 1950px){
                        padding: 0 1.5rem !important;
                    }

                }
                

                & > div img { 
                    height: 30px;
                 }

                & > div span {
                    padding: 15px;
                    background-color: $white;
                    border-radius: 12px;
                    box-shadow: 0px 0px 20px $boxShadow;
                    top: 26px;
                }
                
                &__number {
                    margin-bottom: -5px !important;
                    height: 35px !important;
                }
                
                #step01 {
                    width: 50px;
                }
                
                #step02, #step03 {
                    width: 58px;
                }
                
                #step04 {
                    width: 60px;
                }
                
                .step-step {
                    &__img1 {
                        height: 40px;
                        width: 41px;
                    } 
                    
                    &__img2, &__img4 {
                        height: 40px;
                        width: 40px;
                    }
                    
                    &__img3 {
                        height: 30px;
                        width: 40px;
                        margin-top: 4px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    .wrapper {
        &-text {
            width: 38rem;

            @media (max-width: 1199px) {
                width: 50%;
            }
            
            @media (max-width: 768px) {
                width: 100%;
            }
            
            @media (min-width: 1199px) {
                padding-left: 5rem !important;

            }

            p {
                font-size: 1.7rem !important;
                padding-right: 1.3rem;
                line-height: 2rem !important;
                

                @media (max-width: 1199px) {
                    font-size: 1rem !important;
                    padding-right: 0;
                }
            }

            
        }
    }

    .confian-en-nosotros {

        padding-top: 2.2rem !important;
        @media (max-width: 768px) {
            padding-top: 0.8rem !important;
        } 


        h2 {
            @media (max-width: 768px) {
                font-size: 2rem !important;
                margin-bottom: 1.8rem !important;
            } 
        }

        img {
            width: 113px;
            height: 75px;

            @media (max-width: 425px) {
                width: 90px;
                height: 52px;
            } 
        }

        .each-div-brand {
            @media (min-width: 1200px) {
                width: 16.28%;
            }
        }
    }

    .empresas-publicas {
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        
        &__container {
            background-color: $backgroundGray;
            align-items: center;
            justify-content: center;
            gap: 26px;
            padding: 10px 20px;

            @media (min-width: 992px) {
                gap: 23px;
            }

            @media (max-width: 1000px) {
                gap: 26px;
            }
        }

        .enisa, .aedra, .sigrauto {
            height: 40px;
        }

        .next, .soc, .cdti {
            height: 35px;
        }

        .ue {
            height: 38px;
            width: 170px;
        }

        .soc {
            width: 269px;
            opacity: 0.9;
        }

        .next {
            width: 132px;
        }

        .cdti {
            width: 77px;
        }

        .enisa {
            width: 24px;
        }

        .aedra {
            width: 38px;
        }

        .sigrauto {
            width: 41px;
        }
    }

    .piezas {

        @media (max-width: 768px) {
            height: 5.1rem;
        }

        .pieza {
            border-radius: 6px;
            background: $white;
            padding: 15px;
            box-shadow: 0px 0px 20px $boxShadow;
            margin: 0 0.5rem;
            
            &:first-child { padding-left: 0; }
            &:last-child { padding-right: 0; }
            
            &:nth-child(2n+1) { margin-top: 3.5%; }

            @media (max-width: 768px) {
               padding: 0;
               margin: 0 0.23rem;
               border-radius: 19rem ;
            }

            img {
                width: 175px;
                height: 221px;

                @media (max-width: 768px) {
                    width: 87px;
                    height: 110px;
                }
            }

            &_1 {
                width: 120px !important;
                height: 221px !important;

                @media (max-width: 768px) {
                    width: 60px !important;
                    height: 110px !important;
                }
            }
            
            &_7 {
                width: 117px !important;
                height: 221px !important;

                @media (max-width: 768px) {
                    width: 59px !important;
                    height: 110px !important;
                }
            }
        }
    }

    .img-wrapper {
        background-image: url('../../../../images/foto.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    
    
}

