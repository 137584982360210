@import 'typography';

%height-max { height: 100%; }

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html { @extend %height-max; }

body {
  font-family: $rubik !important;
}

#root { 
  width: 100%; @extend %height-max; 
  overflow: hidden;
}

a, a:hover { text-decoration: unset; }

ul, li { list-style-type: none; }

h1 {
  font-size: 3.75rem !important;
  font-weight: 700 !important;
  margin: 0 !important;
  color: $primary;
  margin: 0;
  @media (max-width: 768px) {
    max-width: 100%;
    font-size: 1.6rem !important;
  }
}

h2 {
  font-size: 2.625rem !important;
  font-weight: 500 !important;
  line-height: 3.094rem !important;
  color: $primary;
  margin: 0;
}

h3 {
  font-size: 2rem !important;
  font-weight: 500 !important;
  color: $primary;
  margin: 0;
}

h4 {
  font-size: 1.75rem !important;
  color: $primary !important;
  font-weight: 700 !important;
}

h5 {
  font-size: 1.375rem !important;
  color: $primary;
  margin: 0;
}

p {
  font-size: 1rem !important;
  color: $fontPrimary !important;
  margin: 0;
  text-align: center;
}

label {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  color: $fontPrimary !important;
}

small {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  color: $primary !important;
}

.MuiPaper-root.MuiPaper-rounded.MuiDialog-paper {
  border-radius: 12px !important;
}